
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import React, { useMemo, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

interface DatePickerProps {
  name?: string,
  startDate?: any,
  endDate?: any,
  onChangePicker?: (params: any) => void;
  placeholder?: string;
  disabled?: boolean,
  isClearable?: boolean,
  minDate?: Date,
  maxDate?: Date
  showOptions?: string[],
  isAutoSelectEndDateOf100YearAfter?: boolean
}

registerLocale('en', en);
registerLocale('ko', ko);

const AnyDatePicker: any = DatePicker;


const DatePickerCustom = ({
  name = '',
  startDate = null,
  endDate = null,
  onChangePicker,
  placeholder,
  disabled = false,
  isClearable = true,
  minDate,
  maxDate,
  showOptions = [
    'today', 'yesterday',
    'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days', 'last_90_days',
    // 'this_week', 'last_week',
    // 'this_month', 'last_month', 'last_3_month', 'last_6_month',
    // 'this_year', 'last_year',
  ],
  isAutoSelectEndDateOf100YearAfter = false

}: DatePickerProps) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleChangePicker = (item: any) => {
    onChangePicker && onChangePicker(item);
  };

  const getDefaultEndDate = (startDate: Date) => {
    if (!startDate) return null;
    const endDate = new Date(startDate);
    endDate.setFullYear(endDate.getFullYear() + 100);
    return endDate;
  };

  const handleSelectPicker = (date: Date, event: any) => {
    if (isAutoSelectEndDateOf100YearAfter) {
      onChangePicker && onChangePicker([date, getDefaultEndDate(date)]);
      setIsOpen(false);
    }
  }

  const optionToLabelMapping: any = {
    today: t('Today'),
    yesterday: t('Yesterday'),
    two_day_ago: t('2 Days ago'),
    last_3_days: t('Last 3 days'),
    last_7_days: t('Last 7 days'),
    last_14_days: t('Last 14 days'),
    last_30_days: t('Last 30 days'),
    last_90_days: t('Last 90 days'),
    this_week: t('This week'),
    last_week: t('Last week'),
    this_month: t('This month'),
    last_month: t('Last month'),
    last_3_month: t('Last 3 months'),
    last_6_month: t('Last 6 months'),
    this_year: t('This year'),
    last_year: t('Last year'),
  };

  const LABEL_OPTIONS = showOptions?.map((item: string) => (optionToLabelMapping[item]));

  const DAYS = [
    { label: optionToLabelMapping?.today, value: [new Date(), new Date()] },
    { label: optionToLabelMapping?.yesterday, value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))] },
    { label: optionToLabelMapping?.two_day_ago, value: [new Date(new Date().setDate(new Date().getDate() - 2)), new Date(new Date().setDate(new Date().getDate() - 2))] },
  ];

  const LAST_DAYS = [
    { label: optionToLabelMapping?.last_3_days, value: [new Date(new Date().setDate(new Date().getDate() - 3)), new Date()] },
    { label: optionToLabelMapping?.last_7_days, value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()], },
    { label: optionToLabelMapping?.last_14_days, value: [new Date(new Date().setDate(new Date().getDate() - 14)), new Date()] },
    { label: optionToLabelMapping?.last_30_days, value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()] },
    { label: optionToLabelMapping?.last_90_days, value: [new Date(new Date().setDate(new Date().getDate() - 90)), new Date()] },
  ];

  const WEEKS = [
    { label: optionToLabelMapping?.this_week, value: [new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1))), new Date()] },
    { label: optionToLabelMapping?.last_week, value: [new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 6)), new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))] },
  ];

  const MONTHS = [
    { label: optionToLabelMapping?.this_month, value: [new Date(new Date().setDate(1)), new Date()] },
    { label: optionToLabelMapping?.last_month, value: [new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)] },
    { label: optionToLabelMapping?.last_3_month, value: [new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)], },
    { label: optionToLabelMapping?.last_6_month, value: [new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)], },
  ];

  const YEARS = [
    { label: optionToLabelMapping?.this_year, value: [new Date(new Date().getFullYear(), 0, 1), new Date()] },
    { label: optionToLabelMapping?.last_year, value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear() - 1, 11, 31)] },
  ];

  const allPresets = [DAYS, LAST_DAYS, WEEKS, MONTHS, YEARS].flat();
  const presets = allPresets?.filter((preset: any) => LABEL_OPTIONS?.includes(preset?.label));

  const handlePresetClick = (preset: any) => {
    onChangePicker && onChangePicker(preset.value);
    setIsOpen(false);
  };

  const checkActive = (starD: any, endD: any, presetValue: any, presetLabel: any) => {
    if (starD?.toDateString() === endD?.toDateString()) {
      return ((presetLabel === optionToLabelMapping?.today && starD?.toDateString() === presetValue[0].toDateString()) ||
        (presetLabel === optionToLabelMapping?.yesterday && starD?.toDateString() === presetValue[0].toDateString()) ||
        (presetLabel === optionToLabelMapping?.two_day_ago && starD?.toDateString() === presetValue[0].toDateString())
      )
    }
    return (starD?.toDateString() === presetValue[0].toDateString() && endD?.toDateString() === presetValue[1].toDateString());
  }

  return (
    <React.Fragment>
      <AnyDatePicker
        name={name}
        selectsRange={true}
        className="form-control search "
        popperClassName="form-control-popper-date-picker-range"
        placeholderText={placeholder || `${t('Date News')}...`}
        startDate={startDate || null}
        endDate={endDate || null}
        dateFormat="yyyy-MM-dd"
        isClearable={isClearable}
        disabled={disabled}
        useShortMonthInDropdown={true}
        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
        // timeIntervals={15}
        onChange={handleChangePicker}
        onSelect={handleSelectPicker}
        onInputClick={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        open={isOpen}
        minDate={minDate}
        maxDate={maxDate}
      >
        <div className="picker-presets">
          <ul className="color-scrollbar-os">
            {presets.map((preset) => (
              <li key={preset.label} onClick={() => handlePresetClick(preset)}
                className={((startDate && endDate) &&
                  checkActive(startDate, endDate, preset.value, preset.label)

                ) ? 'preset-active' : ''}
                ref={(el: any) => {
                  if (((startDate && endDate) && checkActive(startDate, endDate, preset.value, preset.label))) {
                    el?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                      inline: 'start'
                    });
                  }
                }}
              >
                {preset.label}
              </li>
            ))}
          </ul>
        </div>
      </AnyDatePicker>
    </React.Fragment>
  );
};

export default DatePickerCustom;
